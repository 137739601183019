import React from "react"
import propTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.span`
  position: absolute;
  width: 100%;
  z-index: 100;
  bottom: 0;
  text-align: center;
`

const Button = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  userselect: none;
  position: absolute;
  bottom: 0;
  font: 16px/30px sans-serif;
  color: ${props => props.theme.global.colors.brand.light};
  ${props => props.left && "left: 0"}
  ${props => props.right && "right: 0"}
  ${props => !props.display && "none"}
`

export default function Buttons(props) {
  const { index, total, loop, prevHandler, nextHandler } = props
  return (
    <Wrapper>
      <Button left onClick={prevHandler} display={loop || index !== 0}>
        ◀
      </Button>
      <Button right onClick={nextHandler} display={loop || index !== total - 1}>
        ▶
      </Button>
    </Wrapper>
  )
}

Buttons.propTypes = {
  index: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
  prevHandler: propTypes.func,
  nextHandler: propTypes.func,
}
