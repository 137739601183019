import { Button } from "grommet"
import React from "react"
import Anchor from "../common/Anchor"
import SectionLayout from "../Layouts/SectionLayout"

const CTA = ({
  heading,
  sub_heading,
  button_label,
  primary,
  plain,
  button_href,
  color,
  section_size,
}) => {
  return (
    <SectionLayout color={color} width={section_size}>
      <SectionLayout.Header
        heading={heading}
        sub_heading={sub_heading}
        textAlign="center"
      />
      <SectionLayout.Content align="center">
        <Button
          label={button_label}
          plain={plain}
          primary={primary}
          href={button_href}
          as={Anchor}
        />
      </SectionLayout.Content>
    </SectionLayout>
  )
}

CTA.propTypes = {}

export default CTA
