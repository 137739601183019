import React from "react"
import { Box, Text } from "grommet"

import Rating from "react-simple-star-rating"
import CarouselWrapper from "../CarouselWrapper/index.js"
import SectionLayout from "../Layouts/SectionLayout"

const TestimonialItem = ({ author, content, rating }) => {
  return (
    <Box gap="small" align="center" justify="center" alignSelf="center">
      <Text textAlign="center">{content}</Text>
      <Text weight="bold" size="small">
        {author}
      </Text>
      <Rating
        ratingValue={rating}
        size={20}
        transition
        fillColor="orange"
        emptyColor="gray"
      />
    </Box>
  )
}

const Testimonials = ({
  heading,
  sub_heading,
  testimonial_item,
  color,
  ...props
}) => {
  return (
    <SectionLayout color={color}>
      <SectionLayout.Header
        heading={heading}
        sub_heading={sub_heading}
        textAlign="center"
      />
      <SectionLayout.Content>
        <CarouselWrapper>
          {testimonial_item.map(item => (
            <CarouselWrapper.Item>
              <TestimonialItem {...item} />
            </CarouselWrapper.Item>
          ))}
        </CarouselWrapper>
      </SectionLayout.Content>
    </SectionLayout>
  )
}

Testimonials.propTypes = {}

export default Testimonials
