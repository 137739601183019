import Carousel from "../Sections/Carousel"
import ColumnSet from "../Sections/ColumnSet"
import Banner from "../Sections/Banner"
import EmailCollection from "../Sections/EmailCollection"
import PictureGrid from "../Sections/PictureGrid"
import Hero from "../Sections/Hero"
import React from "react"
import Testimonials from "../Sections/Testimonials"
import CTA from "../Sections/CTA"
import Blockquote from "../Sections/Blockquote"
import ButtonList from "../Sections/ButtonList"
import ArticleFeature from "../Sections/ArticleFeature"
import Title from "../Sections/Title"
import SplitScreen from "../Sections/SplitScreen"
import PricingCards from "../Sections/PricingCards"

function SsrDynamic({ sections, localImages }) {
  return sections.map((section, i) => {
    const Component = getComponent(section.strapi_component)
    return (
      <Component
        key={`${section.strapi_component}_${i}`}
        {...section}
        localImages={localImages[section.strapi_component]}
      />
    )
  })
}

const getComponent = strapi_component => {
  switch (strapi_component) {
    case "sections.carousel":
      return Carousel
    case "sections.column-set":
      return ColumnSet
    case "sections.banner":
      return Banner
    case "sections.email-collection":
      return EmailCollection
    case "sections.picture-grid":
      return PictureGrid
    case "sections.hero":
      return Hero
    case "sections.testimonials":
      return Testimonials
    case "sections.cta":
      return CTA
    case "sections.blockquote":
      return Blockquote
    case "sections.button-list":
      return ButtonList
    case "sections.featured-articles":
      return ArticleFeature
    case "sections.title":
      return Title
    case "sections.split-screen":
      return SplitScreen
    case "sections.pricing":
      return PricingCards
    default:
      return () => null
  }
}

export default SsrDynamic
