import React from "react"

import { Button } from "grommet"

import ArticleCard from "../ArticleCard"
import SectionLayout from "../Layouts/SectionLayout"

import { useStaticQuery, graphql } from "gatsby"
import Anchor from "../common/Anchor"

const query = graphql`
  query {
    strapiArticleIndex(locale: { eq: "en" }) {
      path_prefix
    }
  }
`

const ArticleFeatureItem = ({ localImages, ...props }) => {
  const rawImage = localImages.find(img => img.url === props.image.url)
  return <ArticleCard {...props} image={{ localFile: rawImage }} />
}

const ArticleFeature = ({
  heading,
  sub_heading,
  articles,
  cta,
  localImages,
}) => {
  const {
    strapiArticleIndex: { path_prefix },
  } = useStaticQuery(query)
  return (
    <SectionLayout>
      <SectionLayout.Header heading={heading} sub_heading={sub_heading} />
      <SectionLayout.Content align="center">
        {articles.map(article => (
          <ArticleFeatureItem
            key={`ArticleFeatureItem_${article.id}`}
            {...article}
            path_prefix={path_prefix}
            small={true}
            localImages={localImages}
          />
        ))}
        <Button as={Anchor} {...cta} />
      </SectionLayout.Content>
    </SectionLayout>
  )
}

ArticleFeature.propTypes = {}

export default ArticleFeature
