import React from "react"

import { Box, Button, TextInput, Text, Spinner } from "grommet"
import { Send } from "grommet-icons"
import axios from "axios"
import styled from "styled-components"

const RoundButton = styled(Button)`
  border-radius: 50%;
  & > svg {
    display: block;
  }
`

const SubscribeButton = ({ pad, margin, align, endpoint, label }) => {
  const [on, setOn] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [errors, setErrors] = React.useState(null)
  const ref = React.createRef(null)
  React.useEffect(() => {
    if (on) {
      ref.current.focus()
    }
  }, [on])

  if (success) {
    return (
      <Box fill={"horizontal"} pad={pad} margin={margin} align={align}>
        <Button
          primary
          disabled
          onClick={() => setOn(true)}
          label={`Success!  You'll hear from us soon.`}
        />
      </Box>
    )
  }

  if (!on) {
    return (
      <Box fill={"horizontal"} pad={pad} margin={margin} align={align}>
        <Button
          primary
          onClick={() => setOn(true)}
          label={label || `Subscribe Now!`}
        />
      </Box>
    )
  }

  return (
    <Box fill={"horizontal"} pad={pad} margin={margin} align={align}>
      <Box direction="row" gap="small">
        <TextInput
          ref={ref}
          placeholder="plants@gmail.com"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        <RoundButton
          round="full"
          primary
          icon={!submitted ? <Send /> : <Spinner />}
          size="small"
          onClick={async () => {
            if (email) {
              await setSubmitted(true)
              try {
                await axios.post(endpoint, {
                  email: email,
                })
                await setSuccess(true)
                setOn(false)
              } catch (e) {
                setErrors(e.response.data.data.errors.email)
              }
              await setSubmitted(false)
            }
          }}
        />
      </Box>
      {errors && <Text size="xsmall">{errors[0]}</Text>}
    </Box>
  )
}

SubscribeButton.propTypes = {}

export default SubscribeButton
