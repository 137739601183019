import React from "react"
import { Box, Heading, Text } from "grommet"
import Markdown from "../common/Markdown"

const SectionLayout = ({ children, color, width = "xlarge", ...props }) => {
  return (
    <Box
      background={color ? { color, opacity: "weak" } : undefined}
      align="center"
      margin={{ vertical: "medium" }}
    >
      <Box
        width={width}
        gap="small"
        margin={{ vertical: "large" }}
        pad={{ horizontal: "medium" }}
        {...props}
      >
        {children}
      </Box>
    </Box>
  )
}

const Header = ({ heading, sub_heading, markdown, ...props }) => {
  return (
    <Box gap="small" align="center" {...props}>
      <Heading size="medium" textAlign="center" level={2} margin="none">
        {heading}
      </Heading>
      {sub_heading && (
        <Text size="medium" textAlign="center" margin="none" color="brand">
          {sub_heading}
        </Text>
      )}
      {markdown && (
        <Markdown textAlign={{ all: "center" }}>{markdown}</Markdown>
      )}
    </Box>
  )
}

SectionLayout.Header = Header

const Content = ({ children, ...props }) => {
  return <Box {...props}>{children}</Box>
}

SectionLayout.Content = Content

export default SectionLayout
