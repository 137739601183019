import React from "react"

import { Box, Button, ResponsiveContext } from "grommet"
import { LargeColumnContent } from "./ColumnSet"
import SectionLayout from "../Layouts/SectionLayout"
import Anchor from "../common/Anchor"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LazyLoad from "react-lazyload"

const Video = ({ rawImage: { url }, alt = "" }) => {
  return (
    <LazyLoad height={200}>
      <video
        loop={true}
        autoPlay={true}
        width="100%"
        style={{ aspectRatio: `1 / 1` }}
      >
        <source src={url} type="video/mp4" />
      </video>
    </LazyLoad>
  )
}

const useImage = ({ image: imageData, localImages }) => {
  const isImage = !(imageData.ext === ".mp4")
  const rawImage = localImages.find(img => img.url === imageData.url)
  const image = getImage(rawImage)
  const Component = isImage ? GatsbyImage : Video
  const ImageComponent = () => <Component image={image} rawImage={rawImage} />
  return { ImageComponent }
}
const SplitScreen = ({
  heading,
  sub_heading,
  content,
  cta,
  image,
  localImages,
  section_size,
}) => {
  const { ImageComponent } = useImage({ image, localImages })

  return (
    <SectionLayout width={section_size}>
      <SectionLayout.Content width="xlarge" align="center" direction="row">
        <Box
          direction="row-responsive"
          fill={true}
          gap="large"
          align="center"
          pad={{ horizontal: "large" }}
        >
          <Box align="center" gap="medium">
            <LargeColumnContent
              textAlign="center"
              gap="medium"
              heading={heading}
              sub_heading={sub_heading}
              content={content}
            />
            {cta && <Button as={Anchor} {...cta} />}
          </Box>
          <Box width={{ min: "50%" }}>
            <ImageComponent />
          </Box>
        </Box>
      </SectionLayout.Content>
    </SectionLayout>
  )
}

SplitScreen.propTypes = {}

export default SplitScreen
