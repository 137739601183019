import React from "react"
import { Box, Button, Heading, Text } from "grommet"

import { LargeColumnContent } from "./ColumnSet"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SectionLayout from "../Layouts/SectionLayout"
import Anchor from "../common/Anchor"

const ButtonListItem = ({
  header,
  additional_header,
  sub_header,
  image: imageData,
  localImages,
  href,
}) => {
  const rawImage = localImages.find(img => img.url === imageData.url)
  const image = getImage(rawImage)
  return (
    <Box>
      <Button
        as={Anchor}
        onClick={event => event.preventDefault()}
        icon={
          <Box width={"xsmall"} pad="xsmall">
            <GatsbyImage image={image} alt="" />
          </Box>
        }
        label={
          <Box fill direction="row-responsive" pad="medium" gap="medium">
            <Box flex="grow" width={{ max: "small" }}>
              <Heading level={3} margin={{ vertical: "hair" }}>
                {header}
              </Heading>
              <Text size="small">{sub_header}</Text>
            </Box>
            <Box>
              <Text weight="bold">{additional_header}</Text>
            </Box>
          </Box>
        }
        href={href}
      />
    </Box>
  )
}
const ButtonList = ({
  heading,
  sub_heading,
  content,
  button_list_item,
  localImages,
  ...props
}) => {
  return (
    <SectionLayout>
      <SectionLayout.Content direction="row-responsive">
        <LargeColumnContent
          gap="xsmall"
          width="medium"
          heading={heading}
          sub_heading={sub_heading}
          content={content}
        />
        <Box gap="medium">
          {button_list_item.map(item => (
            <ButtonListItem
              key={`button_list_item_${item.id}`}
              localImages={localImages}
              {...item}
            />
          ))}
        </Box>
      </SectionLayout.Content>
    </SectionLayout>
  )
}

ButtonList.propTypes = {}

export default ButtonList
