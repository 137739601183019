import React from "react"
import Markdown from "../common/Markdown"
import SectionLayout from "../Layouts/SectionLayout"
import styled from "styled-components"

const Border = styled.hr`
  width: 30%;
  opacity: 0.6;
`
const Blockquote = ({ heading, sub_heading, border, ...props }) => {
  return (
    <SectionLayout>
      <SectionLayout.Content gap="small" width="medium" alignSelf="center">
        {border && <Border />}
        <Markdown textAlign={{ all: "center" }} textMargin={{ all: "none" }}>
          {heading}
        </Markdown>
        {sub_heading && (
          <Markdown
            textColor={{ all: "brand" }}
            textAlign={{ all: "center" }}
            textMargin={{ all: "none" }}
          >
            {sub_heading}
          </Markdown>
        )}
        {border && <Border />}
      </SectionLayout.Content>
    </SectionLayout>
  )
}

Blockquote.propTypes = {}

export default Blockquote
