import React from "react"
import PropTypes from "prop-types"
import Carousel from "re-carousel"
import IndicatorDots from "./IndicatorDots"
import Buttons from "./Buttons"
import { Box, Text } from "grommet"
import styled from "styled-components"

const StyledCarousel = styled.div`
  position: unset !important;
  .re-carousel {
    ${({ leading = 1 }) => `div:nth-child(${leading}) {
      position: unset !important;
    }`}
    & > div {
      left: 0;
      top: 0;
      display: flex;
    }
  }
`
const CarouselWrapper = ({ children, leading }) => {
  return (
    <StyledCarousel leading={leading}>
      <Carousel
        loop
        auto
        widgets={[IndicatorDots, Buttons]}
        className="re-carousel"
      >
        {children}
      </Carousel>
    </StyledCarousel>
  )
}

const Item = ({ children }) => {
  return (
    <Box
      fill
      pad={{ horizontal: "large" }}
      margin={{ bottom: "xlarge" }}
      align="center"
      justify="center"
    >
      {children}
    </Box>
  )
}

CarouselWrapper.Item = Item

CarouselWrapper.propTypes = {}

export default CarouselWrapper
