import React from "react"
import PropTypes from "prop-types"
import { Box, Heading, Text } from "grommet"
import SectionLayout from "../Layouts/SectionLayout"

const Title = ({ heading, sub_heading, section_size }) => {
  return (
    <SectionLayout margin={"none"} width={section_size}>
      <SectionLayout.Content align="center" margin={"none"} gap="small">
        <Heading level={1} margin={"none"}>
          {heading}
        </Heading>
        <Text size="large" color="brand" size="small">
          {sub_heading}
        </Text>
      </SectionLayout.Content>
    </SectionLayout>
  )
}

Title.propTypes = {}

export default Title
