import React from "react"
import { Box } from "grommet"

import ClientDynamic from "./ClientDynamic"
import SsrDynamic from "./SsrDynamic"

function Dynamic({ sections, localImages }) {
  const isSSR = typeof window === "undefined"
  return (
    <Box fill={true} gap="small">
      {isSSR ? (
        <SsrDynamic sections={sections} localImages={localImages} />
      ) : (
        <ClientDynamic sections={sections} localImages={localImages} />
      )}
    </Box>
  )
}

export default Dynamic
