import React from "react"

import SubscribeButton from "../common/SubscribeButton"
import SectionLayout from "../Layouts/SectionLayout"

const EmailCollection = ({
  content,
  endpoint,
  heading,
  id,
  localImages,
  scroll_percentage,
  button_label,
  section_size,
}) => {
  return (
    <SectionLayout width={section_size}>
      <SectionLayout.Header
        textAlign="center"
        heading={heading}
        markdown={content}
      />
      <SectionLayout.Content>
        <SubscribeButton
          align="center"
          margin="none"
          label={button_label}
          endpoint={endpoint}
        />
      </SectionLayout.Content>
    </SectionLayout>
  )
}

EmailCollection.propTypes = {}

export default EmailCollection
