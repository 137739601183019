import { Box, Heading } from "grommet"
import React from "react"
import Markdown from "../common/Markdown"
import SectionLayout from "../Layouts/SectionLayout"

const Banner = ({ content, heading, id, localImages }) => {
  return (
    <SectionLayout>
      <SectionLayout.Header heading={heading} />
      <SectionLayout.Content>
        <Markdown>{content}</Markdown>
      </SectionLayout.Content>
    </SectionLayout>
  )
}

export default Banner
