import React from "react"
import { FormNextLink } from "grommet-icons"
import { Box, Heading, Text, ResponsiveContext, Button } from "grommet"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Markdown from "../common/Markdown"
import SquareImage from "../common/SquareImage"
import SectionLayout from "../Layouts/SectionLayout"
import Anchor from "../common/Anchor"
import styled from "styled-components"

const ButtonLabel = styled(Text)`
  ${props => props.plain && `font-style: oblique;`}
`
const Header = ({ heading, sub_heading, textAlign }) => {
  return (
    <Box align={textAlign}>
      {sub_heading && (
        <Heading
          level={5}
          textAlign={textAlign}
          margin={{ horizontal: "none", vertical: "small" }}
          color="brand"
        >
          {sub_heading}
        </Heading>
      )}
      <Heading
        level={3}
        textAlign={textAlign}
        margin="none"
        size={sub_heading ? "large" : "medium"}
      >
        {heading}
      </Heading>
    </Box>
  )
}

const ColumnSet = ({
  localImages,
  expanded = false,
  sub_heading,
  heading,
  column: columns,
  cta,
  section_size,
}) => {
  return (
    <SectionLayout width={section_size}>
      <SectionLayout.Header heading={heading} sub_heading={sub_heading} />
      <SectionLayout.Content align="center" gap="medium">
        {!expanded ? (
          <SmallColumnSet
            heading={heading}
            columns={columns}
            localImages={localImages}
          />
        ) : (
          <LargeColumnSet
            heading={heading}
            columns={columns}
            localImages={localImages}
          />
        )}
        {cta && <Button as={Anchor} {...cta} />}
      </SectionLayout.Content>
    </SectionLayout>
  )
}

const LargeColumnSet = ({ heading, columns, localImages }) => {
  return (
    <Box justify="center" gap="xlarge" margin={{ vertical: "large" }}>
      {columns.map((column, i) => (
        <LargeColumn
          key={`${i}_${heading}`}
          index={i}
          {...column}
          localImages={localImages}
        />
      ))}
    </Box>
  )
}

export const LargeColumnContent = ({
  heading,
  sub_heading,
  content,
  textAlign,
  ...props
}) => {
  return (
    <Box {...props}>
      <Header
        sub_heading={sub_heading}
        heading={heading}
        textAlign={textAlign}
      />
      <Markdown
        textAlign={textAlign ? { all: textAlign } : undefined}
        textMargin={{ all: { top: "none" } }}
      >
        {content}
      </Markdown>
    </Box>
  )
}

const LargeColumn = ({
  content,
  heading,
  index,
  image: imageData,
  localImages,
  sub_heading,
  width,
  imageWidth,
  cta,
}) => {
  const rawImage = localImages.find(img => img.url === imageData.url)
  const image = getImage(rawImage)

  const size = React.useContext(ResponsiveContext)

  if (size === "small") {
    return (
      <Box gap="xlarge">
        <Box width={width} align="center" justify="center" fill="horizontal">
          <Box width={imageWidth}>
            <GatsbyImage image={image} alt="" />
          </Box>
        </Box>
        <LargeColumnContent
          textAlign="center"
          gap="large"
          heading={heading}
          sub_heading={sub_heading}
          content={content}
        />
        {cta && (
          <Button
            alignSelf="center"
            as={Anchor}
            {...cta}
            icon={<FormNextLink />}
            reverse
          />
        )}
      </Box>
    )
  }

  const isEven = index % 2 === 0

  return (
    <Box direction={isEven ? "row" : "row-reverse"}>
      <SquareImage width="43%" image={image} alt="" />
      <Box width="7%" />
      <Box width="50%" gap="medium">
        <Header sub_heading={sub_heading} heading={heading} textAlign="start" />
        <Markdown textMargin={{ all: { top: "none" } }}>{content}</Markdown>
        {cta && (
          <Button
            alignSelf="center"
            as={Anchor}
            {...cta}
            label={
              <ButtonLabel size="0.9em" plain={cta.plain}>
                {cta.label}
              </ButtonLabel>
            }
            icon={<FormNextLink />}
            reverse
          />
        )}
      </Box>
    </Box>
  )
}

const SmallColumnSet = ({ heading, columns, localImages }) => {
  return (
    <Box wrap={true} direction="row" justify="center">
      {columns.map((column, i) => (
        <SmallColumn
          key={`${i}_${heading}`}
          {...column}
          localImages={localImages}
        />
      ))}
    </Box>
  )
}

const SmallColumn = ({
  content,
  heading,
  image: imageData,
  localImages,
  sub_heading,
  width,
  imageWidth,
  href,
}) => {
  const rawImage = localImages.find(img => img.url === imageData.url)
  const image = getImage(rawImage)

  const Wrapper = href ? Button : Box

  return (
    <Wrapper
      width={width || "medium"}
      margin="medium"
      as={href ? Anchor : undefined}
      href={href}
      elevation={href ? "small" : undefined}
      round={href ? "small" : undefined}
    >
      <Box align="center" gap="large" pad={href ? "medium" : undefined}>
        <Box width={imageWidth}>
          <SquareImage width="80%" image={image} alt="" />
        </Box>
        <Box gap="medium">
          <Header
            sub_heading={sub_heading}
            heading={heading}
            textAlign="center"
          />
          <Markdown
            textAlign={{ all: "center" }}
            textMargin={{ all: { top: "none" } }}
          >
            {content}
          </Markdown>
        </Box>
      </Box>
    </Wrapper>
  )
}

ColumnSet.propTypes = {}

export default ColumnSet
