import React from "react"
import {
  Box,
  Heading,
  Text,
  ResponsiveContext,
  Button,
  Anchor,
  Grid,
} from "grommet"
import SectionLayout from "../Layouts/SectionLayout"
import CarouselWrapper from "../CarouselWrapper/index.js"
import styled from "styled-components"

const StyledText = styled(Text)`
  opacity: 0.6;
`
const PricingWrapper = ({ children, pricing }) => {
  const size = React.useContext(ResponsiveContext)
  const leadingIndex = pricing.reduce(
    (acc, curr, i, arr) =>
      curr.pricing_item.length > arr[acc].pricing_item.length ? i : acc,
    0
  )

  return size === "small" ? (
    <CarouselWrapper leading={leadingIndex + 1}>{children}</CarouselWrapper>
  ) : (
    <Box direction="row" gap="medium">
      {children}
    </Box>
  )
}

const PricingCard = ({ heading, sub_heading, pricing_item, price, index }) => {
  const size = React.useContext(ResponsiveContext)
  return (
    <Box
      round="small"
      elevation="small"
      fill="horizontal"
      column_index={index}
      rows={pricing_item.length + 1}
      margin={{ top: "large", bottom: size === "small" ? "xlarge" : null }}
    >
      <Box
        border="bottom"
        pad="medium"
        background={{ color: "brand", opacity: "weak" }}
      >
        <Heading level={2} margin="none" textAlign="center">
          {heading}
        </Heading>
        {sub_heading && (
          <Text textAlign="center" margin={{ top: "xsmall" }} size="small">
            <em>{sub_heading}</em>
          </Text>
        )}
      </Box>
      <Box
        pad={{ horizontal: "large", top: "medium", bottom: "small" }}
        align="center"
      >
        <Heading level={2} margin="none">
          {price}
        </Heading>
      </Box>
      <Grid
        margin={{ horizontal: "medium", bottom: "large", top: "medium" }}
        gap="hair"
        columns={["1fr"]}
        rows={pricing_item.map(e => "1fr")}
      >
        {pricing_item.map(({ item_name }) => (
          <Box
            key={item_name}
            fill
            border="bottom"
            align="center"
            justify="center"
            pad="0.8em"
          >
            <Text textAlign="center">{item_name}</Text>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

const PricingCards = ({
  cta,
  footnote,
  pricing,
  heading,
  sub_heading,
  section_size,
}) => {
  return (
    <SectionLayout width={section_size}>
      <SectionLayout.Header heading={heading} sub_heading={sub_heading} />
      <SectionLayout.Content gap="small">
        <PricingWrapper pricing={pricing}>
          {pricing.map((item, i) => (
            <PricingCard {...item} index={i} />
          ))}
        </PricingWrapper>
        <StyledText textAlign="center">{footnote}</StyledText>
        {cta && <Button as={Anchor} {...cta} />}
      </SectionLayout.Content>
    </SectionLayout>
  )
}

PricingCards.propTypes = {}

export default PricingCards

// import { I18n } from "@lingui/react"
// import React from "react"

// import productTiers from "../../constants/productTiers"

// const PricingDetailCardWrapper = ({ children }) => {
//   return (
//     <ResponsiveContext.Consumer>
//       {size =>
//         size !== "large" ? (
//           <Box pad={{ bottom: "xlarge" }}>{children}</Box>
//         ) : (
//           <Box round="small" elevation="small">
//             {children}
//           </Box>
//         )
//       }
//     </ResponsiveContext.Consumer>
//   )
// }

// const PricingDetailCard = ({ tier }) => {
//   return (
//     <I18n>
//       {({ i18n }) => (
//         <PricingDetailCardWrapper>
//           <Box
//             border="bottom"
//             pad="medium"
//             background={{ color: "brand", opacity: "weak" }}
//           >
//             <Heading level={2} margin="none" textAlign="center">
//               {i18n._(tier.title)}
//             </Heading>
//             {tier.subheader && (
//               <Text textAlign="center" margin={{ top: "xsmall" }}>
//                 {i18n._(tier.subheader)}
//               </Text>
//             )}
//           </Box>
//           <Box
//             pad={{ horizontal: "large", top: "medium", bottom: "small" }}
//             align="center"
//           >
//             <Heading level={2} margin="none">
//               ${i18n._(tier.price)}
//             </Heading>
//           </Box>
//           <Box margin={{ horizontal: "large", bottom: "large" }}>
//             <Box gap="medium" border="between" pad={{ top: "medium" }}>
//               {tier.description.map(
//                 line =>
//                   !i18n._(line).startsWith("*") && (
//                     <Text
//                       key={line}
//                       textAlign="center"
//                       margin={{ top: "small" }}
//                     >
//                       {i18n._(line)}
//                     </Text>
//                   )
//               )}
//             </Box>
//           </Box>
//         </PricingDetailCardWrapper>
//       )}
//     </I18n>
//   )
// }

// const PricingDetails = ({ type }) => {
//   return (
//     <PricingWrapper>
//       {productTiers(type).map(tier => (
//         <PricingDetailCard tier={tier} key={tier.title} />
//       ))}
//     </PricingWrapper>
//   )
// }

// const PricingWrapper = ({ children }) => {
//   return (
//     <ResponsiveContext.Consumer>
//       {size =>
//         size !== "large" ? (
//           <Box
//             direction="row"
//             round="small"
//             elevation="small"
//             pad="medium"
//             fill="horizontal"
//           >
//             <Carousel
//               fill
//               play={10000}
//               overflow="hidden"
//               focusIndicator={false}
//             >
//               {children}
//             </Carousel>
//           </Box>
//         ) : (
//           <Box gap="medium" direction="row">
//             {children}
//           </Box>
//         )
//       }
//     </ResponsiveContext.Consumer>
//   )
// }

// PricingDetails.defaultProps = {
//   type: "Will",
// }

// export default PricingDetails
