import React from "react"

import { Box } from "grommet"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Carousel as GrommetCarousel } from "grommet"
import SectionLayout from "../Layouts/SectionLayout"

const CarouselImage = ({ link, image: imageData, id, localImages }) => {
  const rawImage = localImages.find(img => img.url === imageData.url)
  const image = getImage(rawImage)

  return (
    <Box height="large" width="large">
      <GatsbyImage image={image} alt="" />
    </Box>
  )
}

const Carousel = ({ heading, sub_heading, slide: slides, localImages }) => {
  return (
    <SectionLayout>
      <SectionLayout.Header heading={heading} sub_heading={sub_heading} />
      <SectionLayout.Content>
        <GrommetCarousel>
          {slides.map((slide, i) => (
            <CarouselImage
              key={`${i}_${slide.image.url}`}
              {...slide}
              localImages={localImages}
            />
          ))}
        </GrommetCarousel>
      </SectionLayout.Content>
    </SectionLayout>
  )
}

export default Carousel
