import React from "react"

import { Box, Button, Grid, Heading, ResponsiveContext, Text } from "grommet"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import SquareImage from "../common/SquareImage"
import SectionLayout from "../Layouts/SectionLayout"

const GridImage = ({
  image: imageData,
  id,
  localImages,
  title,
  description,
  link,
  hover,
  square,
  width,
}) => {
  const rawImage = localImages.find(img => img.url === imageData.url)
  const image = getImage(rawImage)

  const Component = square ? SquareImage : GatsbyImage

  return (
    <Box width={width} align={"center"}>
      <Component
        fill={true}
        image={image}
        alt=""
        imgStyle={
          hover
            ? {}
            : {
                filter: "brightness(65%)",
              }
        }
      />
    </Box>
  )
}

const withLink = Component => {
  const WrappedComponent = ({ link, ...rest }) => {
    const [hover, setHover] = React.useState(false)
    if (!link) return <Component {...rest} hover={hover} />

    return (
      <Button href={link}>
        <Box
          style={{ zIndex: 1 }}
          margin="none"
          fill={true}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
        >
          <Component {...rest} hover={hover} />
        </Box>
      </Button>
    )
  }
  return WrappedComponent
}

const withHeading = Component => {
  const WrappedComponent = ({ description, title, ...rest }) => {
    if (!title) {
      return <Component {...rest} />
    }
    return (
      <Grid
        rows={["auto"]}
        columns={["auto"]}
        areas={[{ name: "area", start: [0, 0], end: [0, 0] }]}
      >
        <Box gridArea="area">
          <Component {...rest} />
        </Box>
        <Box
          gap="xsmall"
          gridArea="area"
          style={{ zIndex: 4 }}
          justify="center"
          align="center"
          pad="medium"
        >
          <Text
            size="large"
            textAlign="center"
            margin="none"
            weight="bold"
            color="text-contrast"
            alignSelf="center"
          >
            {title}
          </Text>
          {description && (
            <Text
              size="small"
              textAlign="center"
              alignSelf="center"
              margin="none"
              color="text-contrast"
            >
              {description}
            </Text>
          )}
        </Box>
      </Grid>
    )
  }
  return WrappedComponent
}

const PictureGrid = ({
  heading,
  sub_heading,
  grid_picture,
  localImages,
  link,
  width,
  color,
  section_size,
}) => {
  const size = React.useContext(ResponsiveContext)

  const WrappedGridImage = withLink(withHeading(GridImage))

  return (
    <SectionLayout color={color} width={section_size}>
      <SectionLayout.Header heading={heading} sub_heading={sub_heading} />
      <SectionLayout.Content
        wrap={true}
        direction="row"
        align="center"
        justify="evenly"
        fill={true}
        pad={{ horizontal: "medium" }}
      >
        {grid_picture.map(pic => (
          <Box
            key={`${pic.id}_${pic.url}`}
            width={
              size === "small"
                ? pic.width || "medium"
                : `${Math.min(80 / grid_picture.length, 26.6)}%`
            }
            margin="medium"
          >
            <WrappedGridImage
              {...pic}
              width={width}
              localImages={localImages}
            />
          </Box>
        ))}
      </SectionLayout.Content>
    </SectionLayout>
  )
}

PictureGrid.propTypes = {}

export default PictureGrid
