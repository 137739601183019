import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 100;
  bottom: 0px;
  text-align: center;
`

const Dot = styled.span`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${props => props.theme.global.colors.brand.light};
  margin: 7px 5px;
  opacity: ${props => (props.selected ? 1 : 0.3)};
  transition-duration: 300ms;
`

export default function IndicatorDots(props) {
  if (props.total < 2) {
    // Hide dots when there is only one dot.
    return <Wrapper />
  } else {
    return (
      <Wrapper>
        {Array.apply(null, Array(props.total)).map((x, i) => {
          return <Dot key={i} selected={props.index === i} />
        })}
      </Wrapper>
    )
  }
}

IndicatorDots.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}
